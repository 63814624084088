// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
.custom-bg {
  background-color: #f6f6f6 ;
}

.text-color {
  color: #29447a ;
}

.text-color1 {
  color: #2796f0;
}

.border-color {
  border-color: #2977b0;
}

.custom-bg-text {
  color: #f2f0ed;
}

.bg-color {
  background-color: #282f4b;
}

.rounded-card {
  padding: 1.5em .5em .5em;
  border-radius: 2em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.form-card {
  padding: .2em .2em .2em;
  border-radius: 1em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.card {
  transition: transform 0.2s ease;
}

.card:hover .product-card {
  transform: scale(1.02);
}
.card-header{
  height: 7%;
  border-radius: #354482;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;;AAEpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,UAAU;EACV,sBAAsB;AACxB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n \n.custom-bg {\n  background-color: #f6f6f6 ;\n}\n\n.text-color {\n  color: #29447a ;\n}\n\n.text-color1 {\n  color: #2796f0;\n}\n\n.border-color {\n  border-color: #2977b0;\n}\n\n.custom-bg-text {\n  color: #f2f0ed;\n}\n\n.bg-color {\n  background-color: #282f4b;\n}\n\n.rounded-card {\n  padding: 1.5em .5em .5em;\n  border-radius: 2em;\n  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);\n}\n\n.form-card {\n  padding: .2em .2em .2em;\n  border-radius: 1em;\n  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);\n}\n\n.card {\n  transition: transform 0.2s ease;\n}\n\n.card:hover .product-card {\n  transform: scale(1.02);\n}\n.card-header{\n  height: 7%;\n  border-radius: #354482;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
